import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import LoggedInLayout from "layout/LoggedInLayout";
import {PremiumInfo} from "components/Premium";
import {RentalItemsOverviewCard} from "./components/RentalItemsOverviewCard";
import {Button, notification} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {navigate} from "@reach/router";
import { useSmallDeviceSize } from "hooks";
import {hasPermission} from "../../helpers";
import {useAccounts} from "hooks";
import NewRentalItemDrawer from './components/RentalItemsOverviewCard/NewRentalItemDrawer';
import client from "services/HurenApiClient";

const RentalItemsOverview = () => {
    const { t } = useTranslation('common');
    const isSmallDevice = useSmallDeviceSize();
    const {activeAccount} = useAccounts();
    const [drawerVisible, setDrawerVisible] = useState(false);

    const handleSave = async (values) => {
        try {
            const response = await client.post(`/rental_items/${activeAccount.companyId}`, {
                name: values.title,
                clickbox_category_id: values.categoryId
            });
            
            notification.success({
                message: t('notification.messages.success.title'),
                description: t('pages.rentalitems.create.success')
            });
            
            // Navigate to the newly created rental item
            navigate(`/rentalitem?id=${response.data.id}`);
        } catch (error) {
            console.error('Error creating rental item:', error);
            notification.error({
                message: t('notification.messages.error.title'),
                description: t('pages.rentalitems.create.error')
            });
            throw error;
        }
    };

    return <LoggedInLayout pageTitle={<><PremiumInfo/> {t('pages.rentalitems.title')}</>} headerExtras={
        [
            <Button key="1" disabled={!hasPermission(activeAccount, 'manage_rental_items' ,'create')} hidden={isSmallDevice} type="primary" onClick={() => setDrawerVisible(true)}>
                <PlusOutlined />
                {t('pages.rentalitems.new-rental-item')}
            </Button>,
        ]
    }>
        <RentalItemsOverviewCard />
        <NewRentalItemDrawer
            open={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            onSave={handleSave}
        />
    </LoggedInLayout>;
};

export default RentalItemsOverview;
