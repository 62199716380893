import {Table, Card} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {Money} from "../../../../components/Money";

const AdvertisementClickPriceTable = ({clickPriceRules:rules}) => {

    const {t} = useTranslation('common');

    return (
        <Card>
            <Table
                dataSource={rules}
                pagination={false}>
                <Table.Column
                    title={t('pages.advertisements.advertisement-click-price-table.location')}
                    key="geo_location"
                    dataIndex="geo_location"
                    render={(value, record) => value.name}
                />
                <Table.Column
                    title={t('pages.advertisements.advertisement-click-price-table.min_rate')}
                    key="min_rate"
                    dataIndex="min_rate"
                    render= {(text, record) => <Money value={record.min_rate} />}
                />
                <Table.Column
                    title={t('pages.advertisements.advertisement-click-price-table.click_rate')}
                    key="price_formula"
                    dataIndex="price_formula"
                    render= {(text, record) => <Money value={record.price_formula} />}
                />
            </Table>
        </Card>
    )};

export default AdvertisementClickPriceTable;