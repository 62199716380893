import React, { useContext, useEffect, useRef, useState } from 'react';
import {Button, Form, InputNumber, Popconfirm, Select, Table} from 'antd';
import {useTranslation} from "react-i18next";
import {useGetGeoLocations} from "../queries/geoLocations";
import {Money} from "./Money";
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const getMinRate = (ad, geoLocation) => {
    let rate = ad.category.click_out_rate;
    let categoryClickoutRates = ad?.category_click_rates;
    if (categoryClickoutRates) {
        let categoryRate = categoryClickoutRates.find((r) => {
            return r.geo_location.id === geoLocation.id;
        });

        if (categoryRate) {
            rate = categoryRate.rate;
        }
    }
    return rate;
}

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, advertisement, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const { t } = useTranslation('common');
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: t('form.errors.required'),
                    },
                ]}
            >
                <InputNumber
                    ref={inputRef}
                    onPressEnter={save}
                    onBlur={save}
                    decimalSeparator="," precision={2}
                    formatter={value => `€ ${value}`.replace(/^€\d+(,\d{2})$/g, ',')}
                    parser={value => value.replace(/€\s?/g, '')}
                    min={getMinRate(advertisement, record.geo_location)}
                    max="3"
                    step="0.01"/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
const ClickPriceRulesTable = ({ value, onChange, advertisement, loading }) => {

    const { t } = useTranslation('common');

    const { data: geoLocations } = useGetGeoLocations({
        filters: {
            clickPriceRulesEnabled: 1,
        },
        sort: 'name',
        page: 1,
        limit: 100,
    });

    const [dataSource, setDataSource] = useState(value);
    const [count, setCount] = useState(value.length);

    const [selectedGeoLocation, setSelectedGeoLocation] = useState(null);

    const filteredGeoLocations = geoLocations ? geoLocations.filter(geoLocation => {
        const clickPriceRule = dataSource.find(clickPriceRule => {
            return clickPriceRule.geo_location.id === geoLocation.id;
        });
        return !clickPriceRule;
    }) : [];

    const defaultColumns = [
        {
            title: t('components:click-price-rules-table.columns.geo_location.title'),
            dataIndex: 'geo_location.name',
            editable: false,
            render: (_, record) => {
                return record.geo_location.name;
            },
        },
        {
            title: t('components:click-price-rules-table.columns.min_price.title'),
            dataIndex: 'min_price',
            editable: false,
            render: (_, record) => {
                return <Money value={getMinRate(advertisement, record.geo_location)} />;
            },
        },
        {
            title: t('components:click-price-rules-table.columns.price_formula.title'),
            dataIndex: 'price_formula',
            editable: true,
            render: (priceFormula) => {
                return <Money value={priceFormula} />;
            }
        },
        {
            title: t('components:click-price-rules-table.columns.actions.title'),
            dataIndex: 'operation',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm
                        title={t('components:click-price-rules-table.actions.delete.confirm-text')}
                        onConfirm={() => handleDelete(record.key)}
                    >
                        <a>{t('components:click-price-rules-table.actions.delete.label')}</a>
                    </Popconfirm>
                ) : null,
        },
    ];
    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
        if (onChange) {
            onChange(newData);
        }
    };
    const handleAdd = () => {
        let rate = advertisement.click_out_rate;
        let categoryClickoutRates = advertisement?.category_click_rates;
        if (categoryClickoutRates) {
            let categoryRate = categoryClickoutRates.find((r) => {
                return r.geo_location.id === selectedGeoLocation.id;
            });

            if (categoryRate) {
                rate = categoryRate.rate;
            }
        }
        const newItem = {
            key: count,
            geo_location: {
                id: selectedGeoLocation.id,
                name: selectedGeoLocation.name,
            },
            price_formula: rate,
        };

        const newData = [...dataSource, newItem];

        setDataSource(newData);
        setCount(count + 1);
        if (onChange) {
            onChange(newData);
        }
        setSelectedGeoLocation(null);
    };

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
        if (onChange) {
            onChange(newData);
        }
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                advertisement: advertisement,
                handleSave,
            }),
        };
    });

    const handleGeoLocationSelect = (id) => {
        setSelectedGeoLocation(filteredGeoLocations.find(geoLocation => geoLocation.id === id));
    };

    return (
        <div>
            <Table
                loading={loading}
                components={components}
                rowKey="key"
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            <div style={{ display: 'flex', marginTop: '10px' }}>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder={t('components:click-price-rules-table.select-location')}
                    value={selectedGeoLocation?.id}
                    onChange={handleGeoLocationSelect}
                >
                    {filteredGeoLocations.map(geoLocation => {
                        return <Select.Option key={geoLocation.id} value={geoLocation.id}>{geoLocation.name}</Select.Option>
                    })}
                </Select>
                <Button
                    disabled={selectedGeoLocation === null}
                    onClick={handleAdd}
                    type="primary"
                    style={{
                        marginBottom: 16,
                    }}
                >
                    {t('components:click-price-rules-table.actions.add.label')}
                </Button>
            </div>
        </div>
    );
};
export default ClickPriceRulesTable;