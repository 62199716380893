import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, TreeSelect, Typography } from 'antd';
import Drawer from 'components/Drawer';
import SubmitButton from 'components/SubmitButton';
import { useAccounts, useCategories } from 'hooks';

const { Paragraph } = Typography;

const NewRentalItemDrawer = ({ open, onClose, onSave }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation('common');
    const { activeAccount } = useAccounts();
    const [loading, setLoading] = useState(false);
    const { getCategories, categories } = useCategories();

    useEffect(() => {
        getCategories();
    }, []);

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await onSave({
                title: values.title,
                categoryId: values.category
            });
            form.resetFields();
            onClose();
        } catch (error) {
            console.error('Error creating rental item:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to check if a node is selectable (only leaf nodes are selectable)
    const isNodeSelectable = (node) => {
        return node.children.length === 0;
    };

    // Function to transform the tree data to use category_id for both key and value
    const transformTreeData = (nodes) => {
        return nodes.map(node => ({
            title: node.title,
            key: node.category_id,
            value: node.category_id,
            selectable: isNodeSelectable(node),
            children: transformTreeData(node.children)
        }));
    };

    return (
        <Drawer
            title={t('pages.rentalitems.new-rental-item')}
            placement="right"
            onClose={onClose}
            open={open}
            width={520}
        >
            <Paragraph style={{ marginBottom: '24px' }}>
                {t('pages.rentalitems.rental-item.form.intro-text')}
            </Paragraph>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="title"
                    label={t('pages.rentalitems.rental-item.form.title')}
                    rules={[
                        { required: true, message: t('form.errors.required') }
                    ]}
                >
                    <Input placeholder={t('pages.rentalitems.rental-item.form.title-placeholder')} />
                </Form.Item>

                <Form.Item
                    name="category"
                    label={t('pages.rentalitems.rental-item.form.category')}
                    rules={[
                        { required: true, message: t('form.errors.required') }
                    ]}
                >
                    <TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                        placeholder={t('treeselect.placeholder')}
                        allowClear
                        treeData={transformTreeData(categories)}
                        treeNodeFilterProp="title"
                    />
                </Form.Item>

                <SubmitButton loading={loading}>
                    {t('button.text.save')}
                </SubmitButton>
            </Form>
        </Drawer>
    );
};

export default NewRentalItemDrawer; 