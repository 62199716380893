import React, {useState} from 'react'
import { Checkbox, Form, Row, Input, Button, notification } from "antd"
import SubmitButton from "components/SubmitButton"
import {useTranslation} from "react-i18next"
import Drawer from "components/Drawer"
import {useAccounts, useSmallDeviceSize} from "hooks"
import {useGetAccountGroups} from "../../../../queries/users"
import {putInvitation} from "../../../../queries/invitation"

const InvitationDrawer = (props) => {
    const {t} = useTranslation('common')
    const {activeAccount} = useAccounts()
    const [form] = Form.useForm()

    const { data: accountGroups } = useGetAccountGroups()

    const [formValues, setFormValues] = useState({
        email: '',
        groups: []
    });

    const onInnerFinish = async () => {
        await putInvitation(formValues.email, formValues.groups).then(res => {
            notification.success({
                message: t('components:invitation-drawer.notification.success')
            })
            closeDrawer()
        }).catch(error => {
            if (error.response && error.response.status === 400) {

                try {
                    let message = error.response.data.errors.children.email.errors[0];
                    if (message === "There already is an account for this email address") {
                        notification.destroy();
                        notification.warning({
                            message: t('components:invitation-drawer.notification.email')
                        })
                    }
                } catch (e) {}

                try {
                    let message = error.response.data.errors.errors[0];
                    if (message === "Account limit reached") {
                        notification.destroy();
                        notification.warning({
                            message: t('components:invitation-drawer.notification.limit')
                        })
                    }
                } catch (e) {}

            }
        })
    }

    const closeDrawer = () => {
        form.resetFields()
        props.close()
    }

    const groupsChange = (newValue) => {
        setFormValues(prevState => {
            return {...prevState, groups: newValue}
        });
    }

    const emailChange = (e) => {
        setFormValues(prevState => {
            return {...prevState, email: e.target.value}
        });
    }

    const isSmallDevice = useSmallDeviceSize()
    let drawerWidth = 520
    if (isSmallDevice) {
        drawerWidth = '100%'
    }

    return <>
        <Drawer
            title={t('components:invitation-drawer.title')}
            placement="right"
            onClose={closeDrawer}
            open={props.open}
            width={drawerWidth}
            bodyStyle={{ marginBottom: 80 }}
        >
            <Form
                layout="horizontal"
                form={form}
                onFinish={onInnerFinish}
                labelAlign="left"
                labelCol={{span: 12}}
                wrapperCol={{span:12}}
            >
                <Form.Item
                    label={t('entities:invitation.properties.email')}
                    name="email"
                    value={formValues.email}
                    rules={[
                        {type: 'email', message: t('form.errors.invalid-email')},
                        {required: true, message: t('form.errors.required')},
                    ]}
                >
                    <Input onChange={emailChange} placeholder={t('entities:invitation.properties.email')}/>
                </Form.Item>
                <Form.Item
                    label={t('entities:invitation.properties.groups')}
                    name="groups"
                    initialValue={[]}
                    rules={[
                        {required: true, message: t('form.errors.required')},
                        () => ({
                            validator() {
                                if (formValues.groups.length > 0) {
                                    return Promise.resolve()
                                }

                                return Promise.reject(
                                    t('form.errors.min-one')
                                )
                            }
                        })
                    ]}
                >
                    <Row>
                        <Checkbox.Group 
                            onChange={groupsChange} 
                            options={accountGroups?.items.map(column => ({label:column.name, value: column.code}))} 
                        />
                    </Row>
                </Form.Item>
                <div className="actions">
                    <Button onClick={closeDrawer} style={{marginRight: 8}}>
                        {t('button.text.cancel')}
                    </Button>
                    <SubmitButton>
                        {t('button.text.save')}
                    </SubmitButton>
                </div>
            </Form>
        </Drawer>
    </>
}

export default InvitationDrawer
